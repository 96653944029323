'use client'
import React, { useReducer, createContext, useMemo, useEffect } from "react";
import { useRouter } from 'next/navigation'
import dynamic from 'next/dynamic'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import MobileDialog from "./utilities/mobiledialog";
import useUser from "lib/useUser";

const Confetti = dynamic(() => import('react-dom-confetti'), { ssr: false })
const Login = dynamic(() => import('./login/login'), { ssr: false })


export const Context = createContext();

const initialState = {
    confetti: { ready: false },
    backRoute: null,
    progress: false,
    snackbar: { open: false },
    loginScreen: false,
    history: [],
    currentLocation: { lat: 0, lng: 0 }
};

const config = {
    angle: 90,
    spread: "200",
    startVelocity: 60,
    elementCount: 120,
    dragFriction: 0.1,
    duration: "10000",
    stagger: 2,
    width: "10px",
    height: "10px",
    colors: ["#ff2d60", "#75d701", "#fc913a", "#f8ca00", "#8E33F7", "#08182b", "#03a6ff", "#3b8686", "#d62a9d", "#f9320c"]
};

export const ContextProvider = ({ children }) => {
    const router = useRouter()

    const reducer = (state, action) => {
        switch (action.type) {
            case "confettiReady":
                return { ...state, confetti: { ready: true, active: false } };
            case "confettiFire":
                return { ...state, confetti: { ready: true, active: true } };
            case "error":
                return { ...state, snackbar: { open: true, message: action.message, severity: 'error' } };
            case "warning":
                return { ...state, snackbar: { open: true, message: action.message, severity: 'warning' } };
            case "success":
                return { ...state, snackbar: { open: true, message: action.message, severity: 'success' } };
            case "info":
                return { ...state, snackbar: { open: true, message: action.message, severity: 'info' } };
            case "login":
                return { ...state, loginScreen: true };
            case "closeLogin":
                if (state.loginScreen) {
                    return { ...state, loginScreen: false };
                } //Jump to goBack if loginScreen is already closed                   
            case "goBack":
                if (state.history.length > 0 && router.asPath !== state.history[state.history.length - 1]) {
                    router.push(state.history[state.history.length - 1])
                    return { ...state, history: state.history.slice(0, state.history.length - 1) };
                }
                else {
                    router.push('/')
                    return state;
                }
            case "historyPush":
                if (state.history.length === 0 || state.history[state.history.length - 1] !== action.route) {
                    return { ...state, history: [...state.history, action.route] };
                }
                else
                    return state;
            default:
                return {
                    ...state,
                    ...action
                }
        }
    }

    const [state, dispatch] = useReducer(reducer, initialState);

    const contextValue = useMemo(() => {
        return { state, dispatch };
    }, [state, dispatch]);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch({ snackbar: { ...state.snackbar, open: false } });
    };

    return (
        <Context.Provider value={contextValue}>
            {children}
            <MobileDialog open={state.loginScreen} onClose={() => dispatch({ loginScreen: false })}>
                <div className="text-gray-700">
                    <Login title={true} dispatch={dispatch} inline={true} />
                </div>
            </MobileDialog>
            {state.confetti.ready ? <div style={{ left: '20%' }} className="fixed bottom-0">
                <Confetti config={config} active={state.confetti.active} />
            </div> : null}
            <Snackbar className="z-100" open={state.snackbar.open} autoHideDuration={5000} onClose={handleClose}>
                <MuiAlert elevation={6} variant="filled" onClose={handleClose}
                    severity={state.snackbar.severity}>
                    <div dangerouslySetInnerHTML={{ __html: state.snackbar.message }}></div>
                </MuiAlert>
            </Snackbar>
            <span id="phone-sign-in-recaptcha"></span>
        </Context.Provider>
    );
};