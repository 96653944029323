import React, { use, useEffect } from "react";
import Dialog from '@material-ui/core/Dialog';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Slide from '@material-ui/core/Slide';
import useMobileDetect from "lib/hooks/useMobileDetect";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export default function MobileDialog({ children, open, onClose }) {
    const ua = useMobileDetect();
    const neutralizeBack = () => {
        window.history.pushState(null, "", window.location.href);
        window.onpopstate = () => {
          window.history.pushState(null, "", window.location.href);
          handleClose();
        };
      };
      const revivalBack = () => {
        window.onpopstate = undefined;
        window.history.back();
      };
      const handleClose = () => {
        onClose();
        revivalBack();
    }
    
    useEffect(() => {
        if (open) {
            neutralizeBack()
        }
    }, [open])

    return ua.isMobile() ? <SwipeableDrawer anchor="bottom" classes={{ paper: "rounded-none rounded-t-xl" }} open={open} onClose={handleClose}>
        {children}
    </SwipeableDrawer> : <Dialog classes={{ paper: "rounded-none rounded-t-lg m-0 md:rounded-lg", paperFullWidth: "w-full", scrollPaper: "items-end md:items-center" }} fullWidth={true} maxWidth="sm" open={open} onClose={handleClose} TransitionComponent={Transition}>
        {children}
    </Dialog>
}