"use client";

import { ContextProvider } from "components/context";
import { SWRConfig } from "swr";
import * as Sentry from "@sentry/react";
import Custom500 from "pages/500";

export function Providers({ children }) {

  return (
    <Sentry.ErrorBoundary fallback={<Custom500 />} showDialog>
      <SWRConfig
        value={{
          refreshInterval: 0,
          fetcher: (...args) => fetch(...args).then(res => res.json())
        }}
      >
        <ContextProvider>{children}</ContextProvider>
      </SWRConfig>
    </Sentry.ErrorBoundary>
  );
}